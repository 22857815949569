import React from "react"
import { useStaticQuery, Link, graphql } from "gatsby"
import Styles from "./index.module.css"


function SvgComponent(props) {
  return (
    <div>
    <svg viewBox="-0 -0 500 300" {...props}>
      <path fill="none" pointerEvents="all" d="M8 0H208V20H8z" />
      <switch transform="translate(-.5 -.5)">
        <foreignObject
          style={{
            textAlign: "left"
          }}
          pointerEvents="none"
          width="100%"
          height="100%"
          requiredFeatures="http://www.w3.org/TR/SVG11/feature#Extensibility"
          overflow="visible"
        >
          <div
            xmlns="http://www.w3.org/1999/xhtml"
            style={{
              alignItems: "unsafe flex-end",
              justifyContent: "unsafe flex-start",
              width: 198,
              height: 1,
              paddingTop: 17,
              marginLeft: 10
            }}
            display="flex"
          >
            <div
              style={{
                boxSizing: "border-box",
                textAlign: "left"
              }}
              fontSize={0}
            >
              <div
                style={{
                  lineHeight: 1.2,
                  whiteSpace: "normal",
                  wordWrap: "normal"
                }}
                display="inline-block"
                fontSize="12px"
                fontFamily="Helvetica"
                color="#000"
                pointerEvents="all"
              >
                {"\u8272\uFF1A"+props.color_name}
              </div>
            </div>
          </div>
        </foreignObject>
        <text x={10} y={17} fontFamily="Helvetica" fontSize="12px">
          {"\u8272\uFF1A"+props.color_name}
        </text>
      </switch>
      <path fill="none" pointerEvents="all" d="M38 58.45H178V78.45H38z" />
      <switch transform="translate(-.5 -.5)">
        <foreignObject
          style={{
            textAlign: "left"
          }}
          pointerEvents="none"
          width="100%"
          height="100%"
          requiredFeatures="http://www.w3.org/TR/SVG11/feature#Extensibility"
          overflow="visible"
        >
          <div
            xmlns="http://www.w3.org/1999/xhtml"
            style={{
              alignItems: "unsafe center",
              justifyContent: "unsafe flex-start",
              width: 138,
              height: 1,
              paddingTop: 68,
              marginLeft: 40
            }}
            display="flex"
          >
            <div
              style={{
                boxSizing: "border-box",
                textAlign: "left"
              }}
              fontSize={0}
            >
              <div
                style={{
                  lineHeight: 1.2,
                  whiteSpace: "normal",
                  wordWrap: "normal"
                }}
                display="inline-block"
                fontSize="12px"
                fontFamily="Helvetica"
                color="#000"
                pointerEvents="all"
              >
                {props.kokudo_hanrei1_name}
              </div>
            </div>
          </div>
        </foreignObject>
        <text x={40} y={72} fontFamily="Helvetica" fontSize="12px">
          {props.kokudo_hanrei1_name}
        </text>
      </switch>
      <path
        fill={props.hanrei1_color}
        stroke="#fff"
        strokeWidth={2}
        pointerEvents="all"
        d="M8 67H28V87H8z"
      />
      <path fill="none" pointerEvents="all" d="M38 92.95H148V112.95H38z" />
      <switch transform="translate(-.5 -.5)">
        <foreignObject
          style={{
            textAlign: "left"
          }}
          pointerEvents="none"
          width="100%"
          height="100%"
          requiredFeatures="http://www.w3.org/TR/SVG11/feature#Extensibility"
          overflow="visible"
        >
          <div
            xmlns="http://www.w3.org/1999/xhtml"
            style={{
              alignItems: "unsafe center",
              justifyContent: "unsafe flex-start",
              width: 108,
              height: 1,
              paddingTop: 103,
              marginLeft: 40
            }}
            display="flex"
          >
            <div
              style={{
                boxSizing: "border-box",
                textAlign: "left"
              }}
              fontSize={0}
            >
              <div
                style={{
                  lineHeight: 1.2,
                  whiteSpace: "normal",
                  wordWrap: "normal"
                }}
                display="inline-block"
                fontSize="12px"
                fontFamily="Helvetica"
                color="#000"
                pointerEvents="all"
              >
                {props.kokudo_hanrei2_name}
              </div>
            </div>
          </div>
        </foreignObject>
        <text x={40} y={107} fontFamily="Helvetica" fontSize="12px">
          {props.kokudo_hanrei2_name}
        </text>
      </switch>
      <path
        fill={props.hanrei2_color}
        stroke="#fff"
        strokeWidth={2}
        pointerEvents="all"
        d="M8 102H28V122H8z"
      />
      <path fill="none" pointerEvents="all" d="M38 127H148V147H38z" />
      <switch transform="translate(-.5 -.5)">
        <foreignObject
          style={{
            textAlign: "left"
          }}
          pointerEvents="none"
          width="100%"
          height="100%"
          requiredFeatures="http://www.w3.org/TR/SVG11/feature#Extensibility"
          overflow="visible"
        >
          <div
            xmlns="http://www.w3.org/1999/xhtml"
            style={{
              alignItems: "unsafe center",
              justifyContent: "unsafe flex-start",
              width: 108,
              height: 1,
              paddingTop: 137,
              marginLeft: 40
            }}
            display="flex"
          >
            <div
              style={{
                boxSizing: "border-box",
                textAlign: "left"
              }}
              fontSize={0}
            >
              <div
                style={{
                  lineHeight: 1.2,
                  whiteSpace: "normal",
                  wordWrap: "normal"
                }}
                display="inline-block"
                fontSize="12px"
                fontFamily="Helvetica"
                color="#000"
                pointerEvents="all"
              >
                {props.kokudo_hanrei3_name}
              </div>
            </div>
          </div>
        </foreignObject>
        <text x={40} y={141} fontFamily="Helvetica" fontSize="12px">
          {props.kokudo_hanrei3_name}
        </text>
      </switch>
      <path
        fill={props.hanrei3_color}
        stroke="#fff"
        strokeWidth={2}
        pointerEvents="all"
        d="M8 136H28V156H8z"
      />
      <path
        d="M296 19l25 12v127l-25 12-25-12V31z"
        fill={props.hanrei1_color}
        stroke="#fff"
        strokeWidth={2}
        strokeMiterlimit={10}
        pointerEvents="all"
      />
      <path
        d="M271 31l25 12.01L321 31m-25 12.01V170"
        fill="none"
        stroke="#fff"
        strokeWidth={2}
        strokeMiterlimit={10}
        pointerEvents="all"
      />
      <path
        d="M256 49l25 12v107l-25 12-25-12V61z"
        fill={props.hanrei2_color}
        stroke="#fff"
        strokeWidth={2}
        strokeMiterlimit={10}
        pointerEvents="all"
      />
      <path
        d="M231 61l25 12.01L281 61m-25 12.01V180"
        fill="none"
        stroke="#fff"
        strokeWidth={2}
        strokeMiterlimit={10}
        pointerEvents="all"
      />
      <path
        d="M216 99l25 12v67l-25 12-25-12v-67z"
        fill={props.hanrei3_color}
        stroke="#fff"
        strokeWidth={2}
        strokeMiterlimit={10}
        pointerEvents="all"
      />
      <path
        d="M191 111l25 12.01L241 111m-25 12.01V190"
        fill="none"
        stroke="#fff"
        strokeWidth={2}
        strokeMiterlimit={10}
        pointerEvents="all"
      />
      <path
        d="M8 36.25h251.76"
        fill="none"
        stroke="#000"
        strokeWidth={2}
        strokeMiterlimit={10}
        pointerEvents="stroke"
      />
      <path
        d="M265.76 36.25l-8 4 2-4-2-4z"
        stroke="#000"
        strokeWidth={2}
        strokeMiterlimit={10}
        pointerEvents="all"
      />
      <path fill="none" pointerEvents="all" d="M38 161H148V181H38z" />
      <switch transform="translate(-.5 -.5)">
        <foreignObject
          style={{
            textAlign: "left"
          }}
          pointerEvents="none"
          width="100%"
          height="100%"
          requiredFeatures="http://www.w3.org/TR/SVG11/feature#Extensibility"
          overflow="visible"
        >
          <div
            xmlns="http://www.w3.org/1999/xhtml"
            style={{
              alignItems: "unsafe center",
              justifyContent: "unsafe flex-start",
              width: 108,
              height: 1,
              paddingTop: 171,
              marginLeft: 40
            }}
            display="flex"
          >
            <div
              style={{
                boxSizing: "border-box",
                textAlign: "left"
              }}
              fontSize={0}
            >
              <div
                style={{
                  lineHeight: 1.2,
                  whiteSpace: "normal",
                  wordWrap: "normal"
                }}
                display="inline-block"
                fontSize="12px"
                fontFamily="Helvetica"
                color="#000"
                pointerEvents="all"
              >
                {props.kokudo_hanrei4_name}
              </div>
            </div>
          </div>
        </foreignObject>
        <text x={40} y={175} fontFamily="Helvetica" fontSize="12px">
          {props.kokudo_hanrei4_name}
        </text>
      </switch>
      <path
        fill={props.hanrei4_color}
        stroke="#fff"
        strokeWidth={2}
        pointerEvents="all"
        d="M8 170H28V190H8z"
      />
      <path
        d="M333.51 160.46h32.5m-32.5-130.2h32.5m-16.25 130.2V30.26"
        fill="#fff"
        stroke="#000"
        strokeWidth={2}
        strokeMiterlimit={10}
        pointerEvents="all"
      />
      <path fill="none" pointerEvents="all" d="M321 45.25H441V65.25H321z" />
      <switch transform="translate(-.5 -.5)">
        <foreignObject
          style={{
            textAlign: "left"
          }}
          pointerEvents="none"
          width="100%"
          height="100%"
          requiredFeatures="http://www.w3.org/TR/SVG11/feature#Extensibility"
          overflow="visible"
        >
          <div
            xmlns="http://www.w3.org/1999/xhtml"
            style={{
              alignItems: "unsafe flex-end",
              justifyContent: "unsafe center",
              width: 118,
              height: 1,
              paddingTop: 62,
              marginLeft: 322
            }}
            display="flex"
          >
            <div
              style={{
                boxSizing: "border-box",
                textAlign: "center"
              }}
              fontSize={0}
            >
              <div
                style={{
                  lineHeight: 1.2,
                  whiteSpace: "normal",
                  wordWrap: "normal"
                }}
                display="inline-block"
                fontSize="12px"
                fontFamily="Helvetica"
                color="#000"
                pointerEvents="all"
              >
                {"\u9AD8\u3055\uFF1A"}
              </div>
            </div>
          </div>
        </foreignObject>
        <text
          x={381}
          y={62}
          fontFamily="Helvetica"
          fontSize="12px"
          textAnchor="middle"
        >
          {"\u9AD8\u3055\uFF1A"}
        </text>
      </switch>
      <path fill="none" pointerEvents="all" d="M351 75.95H498V121H351z" />
      <switch transform="translate(-.5 -.5)">
        <foreignObject
          style={{
            textAlign: "left"
          }}
          pointerEvents="none"
          width="100%"
          height="100%"
          requiredFeatures="http://www.w3.org/TR/SVG11/feature#Extensibility"
          overflow="visible"
        >
          <div
            xmlns="http://www.w3.org/1999/xhtml"
            style={{
              alignItems: "unsafe flex-start",
              justifyContent: "unsafe center",
              width: 145,
              height: 1,
              paddingTop: 83,
              marginLeft: 352
            }}
            display="flex"
          >
            <div
              style={{
                boxSizing: "border-box",
                textAlign: "center"
              }}
              fontSize={0}
            >
              <div
                style={{
                  lineHeight: 1.2,
                  whiteSpace: "normal",
                  wordWrap: "normal"
                }}
                display="inline-block"
                fontSize="12px"
                fontFamily="Helvetica"
                color="#000"
                pointerEvents="all"
              >
                {props.height_name}{props.kokudo_height_ratio}
              </div>
            </div>
          </div>
        </foreignObject>
        <text
          x={425}
          y={95}
          fontFamily="Helvetica"
          fontSize="12px"
          textAnchor="middle"
        >
          {props.height_name}{props.kokudo_height_ratio}
        </text>
      </switch>
      <path fill="none" pointerEvents="all" d="M8 220H208V240H8z" />
      <switch transform="translate(-.5 -.5)">
        <foreignObject
          style={{
            textAlign: "left"
          }}
          pointerEvents="none"
          width="100%"
          height="100%"
          requiredFeatures="http://www.w3.org/TR/SVG11/feature#Extensibility"
          overflow="visible"
        >
          <div
            xmlns="http://www.w3.org/1999/xhtml"
            style={{
              alignItems: "unsafe flex-end",
              justifyContent: "unsafe flex-start",
              width: 198,
              height: 1,
              paddingTop: 237,
              marginLeft: 10
            }}
            display="flex"
          >
            <div
              style={{
                boxSizing: "border-box",
                textAlign: "left"
              }}
              fontSize={0}
            >
              <div
                style={{
                  lineHeight: 1.2,
                  whiteSpace: "normal",
                  wordWrap: "normal"
                }}
                display="inline-block"
                fontSize="12px"
                fontFamily="Helvetica"
                color="#000"
                pointerEvents="all"
              >
                {"\u9AD8\u3055\uFF1A"+props.height_origin}
              </div>
            </div>
          </div>
        </foreignObject>
        <text x={10} y={237} fontFamily="Helvetica" fontSize="12px">
          {"\u9AD8\u3055\uFF1A"+props.height_origin}
        </text>
      </switch>
      <path fill="none" pointerEvents="all" d="M258 220H458V240H258z" />
      <switch transform="translate(-.5 -.5)">
        <foreignObject
          style={{
            textAlign: "left"
          }}
          pointerEvents="none"
          width="100%"
          height="100%"
          requiredFeatures="http://www.w3.org/TR/SVG11/feature#Extensibility"
          overflow="visible"
        >
          <div
            xmlns="http://www.w3.org/1999/xhtml"
            style={{
              alignItems: "unsafe flex-end",
              justifyContent: "unsafe flex-start",
              width: 198,
              height: 1,
              paddingTop: 237,
              marginLeft: 260
            }}
            display="flex"
          >
            <div
              style={{
                boxSizing: "border-box",
                textAlign: "left"
              }}
              fontSize={0}
            >
              <div
                style={{
                  lineHeight: 1.2,
                  whiteSpace: "normal",
                  wordWrap: "normal"
                }}
                display="inline-block"
                fontSize="12px"
                fontFamily="Helvetica"
                color="#000"
                pointerEvents="all"
              >
                {"\u8272\uFF1A"+props.height_origin}
              </div>
            </div>
          </div>
        </foreignObject>
        <text x={260} y={237} fontFamily="Helvetica" fontSize="12px">
          {"\u8272\uFF1A"+props.height_origin}
        </text>
      </switch>
      <path fill="none" pointerEvents="all" d="M8 190H378V210H8z" />
      <switch transform="translate(-.5 -.5)">
        <foreignObject
          style={{
            textAlign: "left"
          }}
          pointerEvents="none"
          width="100%"
          height="100%"
          requiredFeatures="http://www.w3.org/TR/SVG11/feature#Extensibility"
          overflow="visible"
        >
          <div
            xmlns="http://www.w3.org/1999/xhtml"
            style={{
              alignItems: "unsafe flex-end",
              justifyContent: "unsafe flex-start",
              width: 368,
              height: 1,
              paddingTop: 207,
              marginLeft: 10
            }}
            display="flex"
          >
            <div
              style={{
                boxSizing: "border-box",
                textAlign: "left"
              }}
              fontSize={0}
            >
              <div
                style={{
                  lineHeight: 1.2,
                  whiteSpace: "normal",
                  wordWrap: "normal"
                }}
                display="inline-block"
                fontSize="12px"
                fontFamily="Helvetica"
                color="#000"
                pointerEvents="all"
              >
                {"\u5E74\u6B21\uFF1A"+props.year_display}
              </div>
            </div>
          </div>
        </foreignObject>
        <text x={10} y={207} fontFamily="Helvetica" fontSize="12px">
          {"\u5E74\u6B21\uFF1A"+props.year_display}
        </text>
      </switch>
    </svg>
    </div>
  )
}

export default SvgComponent



//     function SvgComponent(props) {
//       return (
//     <svg
//       width="500px"
//       viewBox="-0.5 -0.5 472 172"
//       onclick="(function(svg){var src=window.event.target||window.event.srcElement;while (src!=null&amp;&amp;src.nodeName.toLowerCase()!='a'){src=src.parentNode;}if(src==null){if(svg.wnd!=null&amp;&amp;!svg.wnd.closed){svg.wnd.focus();}else{var r=function(evt){if(evt.data=='ready'&amp;&amp;evt.source==svg.wnd){svg.wnd.postMessage(decodeURIComponent(svg.getAttribute('content')),'*');window.removeEventListener('message',r);}};window.addEventListener('message',r);svg.wnd=window.open('https://viewer.diagrams.net/?client=1&amp;page=0&amp;edit=_blank');}}})(this);"
//       style={{
//         maxWidth: "100%",
//         maxHeight: 172
//       }}
//       cursor="pointer"
//       {...props}
//     >
//       <path fill="none" pointerEvents="all" d="M8 0H208V20H8z" />
//       <switch transform="translate(-.5 -.5)">
//         <foreignObject
//           style={{
//             textAlign: "left"
//           }}
//           pointerEvents="none"
//           width="100%"
//           height="100%"
//           requiredFeatures="http://www.w3.org/TR/SVG11/feature#Extensibility"
//           overflow="visible"
//         >
//           <div
//             xmlns="http://www.w3.org/1999/xhtml"
//             style={{
//               alignItems: "unsafe flex-end",
//               justifyContent: "unsafe flex-start",
//               width: 198,
//               height: 1,
//               paddingTop: 15,
//               marginLeft: 10
//             }}
//             display="flex"
//           >
//             <div
//               style={{
//                 boxSizing: "border-box",
//                 textAlign: "left"
//               }}
//               fontSize={0}
//             >
//               <div
//                 style={{
//                   lineHeight: 1.2,
//                   whiteSpace: "normal",
//                   wordWrap: "normal"
//                 }}
//                 display="inline-block"
//                 fontSize="12px"
//                 fontFamily="Helvetica"
//                 color="#000"
//                 pointerEvents="all"
//               >
//                 <span>
//                 {"\u8272\uFF1A"+props.color_name}
//                 </span>
//               </div>
//             </div>
//           </div>
//         </foreignObject>
//         <text x={10} y={17} fontFamily="Helvetica" fontSize="12px">
//              {"\u8272\uFF1A"+props.color_name}
//         </text>
//       </switch>
//       <path fill="none" pointerEvents="all" d="M38 43.45H178V63.45H38z" />
//       <switch transform="translate(-.5 -.5)">
//         <foreignObject
//           style={{
//             textAlign: "left"
//           }}
//           pointerEvents="none"
//           width="100%"
//           height="100%"
//           requiredFeatures="http://www.w3.org/TR/SVG11/feature#Extensibility"
//           overflow="visible"
//         >
//           <div
//             xmlns="http://www.w3.org/1999/xhtml"
//             style={{
//               alignItems: "unsafe center",
//               justifyContent: "unsafe flex-start",
//               width: 138,
//               height: 1,
//               paddingTop: 50,
//               marginLeft: 40
//             }}
//             display="flex"
//           >
//             <div
//               style={{
//                 boxSizing: "border-box",
//                 textAlign: "left"
//               }}
//               fontSize={0}
//             >
//               <div
//                 style={{
//                   lineHeight: 1.2,
//                   whiteSpace: "normal",
//                   wordWrap: "normal"
//                 }}
//                 display="inline-block"
//                 fontSize="12px"
//                 fontFamily="Helvetica"
//                 color="#000"
//                 pointerEvents="all"
//               >
//                 {props.kokudo_hanrei1_name}
//               </div>
//             </div>
//           </div>
//         </foreignObject>
//         <text x={40} y={57} fontFamily="Helvetica" fontSize="12px">
//           {props.kokudo_hanrei1_name}
//         </text>
//       </switch>
//       <path
//         fill={props.hanrei1_color}
//         stroke="#fff"
//         strokeWidth={2}
//         pointerEvents="all"
//         d="M8 50H28V70H8z"
//       />
//       <path fill="none" pointerEvents="all" d="M38 75.95H148V95.95H38z" />
//       <switch transform="translate(-.5 -.5)">
//         <foreignObject
//           style={{
//             textAlign: "left"
//           }}
//           pointerEvents="none"
//           width="100%"
//           height="100%"
//           requiredFeatures="http://www.w3.org/TR/SVG11/feature#Extensibility"
//           overflow="visible"
//         >
//           <div
//             xmlns="http://www.w3.org/1999/xhtml"
//             style={{
//               alignItems: "unsafe center",
//               justifyContent: "unsafe flex-start",
//               width: 108,
//               height: 1,
//               paddingTop: 85,
//               marginLeft: 40
//             }}
//             display="flex"
//           >
//             <div
//               style={{
//                 boxSizing: "border-box",
//                 textAlign: "left"
//               }}
//               fontSize={0}
//             >
//               <div
//                 style={{
//                   lineHeight: 1.2,
//                   whiteSpace: "normal",
//                   wordWrap: "normal"
//                 }}
//                 display="inline-block"
//                 fontSize="12px"
//                 fontFamily="Helvetica"
//                 color="#000"
//                 pointerEvents="all"
//               >
//                 {props.kokudo_hanrei2_name}
//               </div>
//             </div>
//           </div>
//         </foreignObject>
//         <text x={40} y={90} fontFamily="Helvetica" fontSize="12px">
//           {props.kokudo_hanrei2_name}
//         </text>
//       </switch>
//       <path
//         fill={props.hanrei2_color}
//         stroke="#fff"
//         strokeWidth={2}
//         pointerEvents="all"
//         d="M8 85H28V105H8z"
//       />
//       <path fill="none" pointerEvents="all" d="M38 112H148V132H38z" />
//       <switch transform="translate(-.5 -.5)">
//         <foreignObject
//           style={{
//             textAlign: "left"
//           }}
//           pointerEvents="none"
//           width="100%"
//           height="100%"
//           requiredFeatures="http://www.w3.org/TR/SVG11/feature#Extensibility"
//           overflow="visible"
//         >
//           <div
//             xmlns="http://www.w3.org/1999/xhtml"
//             style={{
//               alignItems: "unsafe center",
//               justifyContent: "unsafe flex-start",
//               width: 108,
//               height: 1,
//               paddingTop: 118,
//               marginLeft: 40
//             }}
//             display="flex"
//           >
//             <div
//               style={{
//                 boxSizing: "border-box",
//                 textAlign: "left"
//               }}
//               fontSize={0}
//             >
//               <div
//                 style={{
//                   lineHeight: 1.2,
//                   whiteSpace: "normal",
//                   wordWrap: "normal"
//                 }}
//                 display="inline-block"
//                 fontSize="12px"
//                 fontFamily="Helvetica"
//                 color="#000"
//                 pointerEvents="all"
//               >
//                 {props.kokudo_hanrei3_name}
//               </div>
//             </div>
//           </div>
//         </foreignObject>
//         <text x={40} y={126} fontFamily="Helvetica" fontSize="12px">
//           {props.kokudo_hanrei3_name}
//         </text>
//       </switch>
//       <path
//         fill={props.hanrei3_color}
//         stroke="#fff"
//         strokeWidth={2}
//         pointerEvents="all"
//         d="M8 115H28V135H8z"
//       />
//       <path
//         d="M296 10l25 12v111l-25 12-25-12V22z"
//         fill={props.hanrei1_color}
//         stroke="#fff"
//         strokeWidth={2}
//         strokeMiterlimit={10}
//         pointerEvents="all"
//       />
//       <path
//         d="M271 22l25 12.01L321 22m-25 12.01V145"
//         fill="none"
//         stroke="#fff"
//         strokeWidth={2}
//         strokeMiterlimit={10}
//         pointerEvents="all"
//       />
//       <path
//         d="M256 40l25 12v86l-25 12-25-12V52z"
//         fill={props.hanrei2_color}
//         stroke="#fff"
//         strokeWidth={2}
//         strokeMiterlimit={10}
//         pointerEvents="all"
//       />
//       <path
//         d="M231 52l25 12.01L281 52m-25 12.01V150"
//         fill="none"
//         stroke="#fff"
//         strokeWidth={2}
//         strokeMiterlimit={10}
//         pointerEvents="all"
//       />
//       <path
//         d="M216 90l25 12v46l-25 12-25-12v-46z"
//         fill={props.hanrei3_color}
//         stroke="#fff"
//         strokeWidth={2}
//         strokeMiterlimit={10}
//         pointerEvents="all"
//       />
//       <path
//         d="M191 102l25 12.01L241 102m-25 12.01V160"
//         fill="none"
//         stroke="#fff"
//         strokeWidth={2}
//         strokeMiterlimit={10}
//         pointerEvents="all"
//       />
//       <path
//         d="M8 36.25h251.76"
//         fill="none"
//         stroke="#000"
//         strokeWidth={2}
//         strokeMiterlimit={10}
//         pointerEvents="stroke"
//       />
//       <path
//         d="M265.76 36.25l-8 4 2-4-2-4z"
//         stroke="#000"
//         strokeWidth={2}
//         strokeMiterlimit={10}
//         pointerEvents="all"
//       />
//       <path fill="none" pointerEvents="all" d="M38 145H148V165H38z" />
//       <switch transform="translate(-.5 -.5)">
//         <foreignObject
//           style={{
//             textAlign: "left"
//           }}
//           pointerEvents="none"
//           width="100%"
//           height="100%"
//           requiredFeatures="http://www.w3.org/TR/SVG11/feature#Extensibility"
//           overflow="visible"
//         >
//           <div
//             xmlns="http://www.w3.org/1999/xhtml"
//             style={{
//               alignItems: "unsafe center",
//               justifyContent: "unsafe flex-start",
//               width: 108,
//               height: 1,
//               paddingTop: 150,
//               marginLeft: 40
//             }}
//             display="flex"
//           >
//             <div
//               style={{
//                 boxSizing: "border-box",
//                 textAlign: "left"
//               }}
//               fontSize={0}
//             >
//               <div
//                 style={{
//                   lineHeight: 1.2,
//                   whiteSpace: "normal",
//                   wordWrap: "normal"
//                 }}
//                 display="inline-block"
//                 fontSize="12px"
//                 fontFamily="Helvetica"
//                 color="#000"
//                 pointerEvents="all"
//               >
//                 {props.kokudo_hanrei4_name}
//               </div>
//             </div>
//           </div>
//         </foreignObject>
//         <text x={40} y={159} fontFamily="Helvetica" fontSize="12px">
//           {props.kokudo_hanrei4_name}
//         </text>
//       </switch>
//       <path
//         fill={props.hanrei4_color}
//         stroke="#fff"
//         strokeWidth={2}
//         pointerEvents="all"
//         d="M8 150H28V170H8z"
//       />
//       <path
//         d="M333.51 138.14h32.5m-32.5-116.88h32.5m-16.25 116.88V21.26"
//         fill="#fff"
//         stroke="#000"
//         strokeWidth={2}
//         strokeMiterlimit={10}
//         pointerEvents="all"
//       />
//       <path fill="none" pointerEvents="all" d="M321 36.25H441V56.25H321z" />
//       <switch transform="translate(-.5 -.5)">
//         <foreignObject
//           style={{
//             textAlign: "left"
//           }}
//           pointerEvents="none"
//           width="100%"
//           height="100%"
//           requiredFeatures="http://www.w3.org/TR/SVG11/feature#Extensibility"
//           overflow="visible"
//         >
//           <div
//             xmlns="http://www.w3.org/1999/xhtml"
//             style={{
//               alignItems: "unsafe flex-end",
//               justifyContent: "unsafe center",
//               width: 118,
//               height: 1,
//               paddingTop: 40,
//               marginLeft: 325
//             }}
//             display="flex"
//           >
//             <div
//               style={{
//                 boxSizing: "border-box",
//                 textAlign: "center"
//               }}
//               fontSize={0}
//             >
//               <div
//                 style={{
//                   lineHeight: 1.2,
//                   whiteSpace: "normal",
//                   wordWrap: "normal"
//                 }}
//                 display="inline-block"
//                 fontSize="12px"
//                 fontFamily="Helvetica"
//                 color="#000"
//                 pointerEvents="all"
//               >
//                 {"\u9AD8\u3055\uFF1A"}
//               </div>
//             </div>
//           </div>
//         </foreignObject>
//         <text
//           x={381}
//           y={53}
//           fontFamily="Helvetica"
//           fontSize="12px"
//           textAnchor="middle"
//         >
//           {"\u9AD8\u3055\uFF1A"}
//         </text>
//       </switch>
//       <path fill="none" pointerEvents="all" d="M351 75.95H471V95.95H351z" />
//       <g transform="translate(-.5 -.5)">
//         <switch>
//           <foreignObject
//             style={{
//               textAlign: "left"
//             }}
//             pointerEvents="none"
//             width="100%"
//             height="100%"
//             requiredFeatures="http://www.w3.org/TR/SVG11/feature#Extensibility"
//             overflow="visible"
//           >
//             <div
//               xmlns="http://www.w3.org/1999/xhtml"
//               style={{
//                 alignItems: "unsafe flex-start",
//                 justifyContent: "unsafe center",
//                 width: 108,
//                 height: 1,
//                 paddingTop: 70,
//                 marginLeft: 362
//               }}
//               display="flex"
//             >
//               <div
//                 style={{
//                   boxSizing: "border-box",
//                   textAlign: "center"
//                 }}
//                 fontSize={0}
//               >
//                 <div
//                   style={{
//                     lineHeight: 1.2,
//                     whiteSpace: "normal",
//                     wordWrap: "normal"
//                   }}
//                   display="inline-block"
//                   fontSize="12px"
//                   fontFamily="Helvetica"
//                   color="#000"
//                   pointerEvents="all"
//                 >
//                   {props.height_name}
//                 </div>
//               </div>
//             </div>
//           </foreignObject>
//           <text
//             x={411}
//             y={95}
//             fontFamily="Helvetica"
//             fontSize="12px"
//             textAnchor="middle"
//           >
//             {props.height_name}
//           </text>
//         </switch>
//         <switch>
//           <foreignObject
//             style={{
//               textAlign: "left"
//             }}
//             pointerEvents="none"
//             width="100%"
//             height="100%"
//             requiredFeatures="http://www.w3.org/TR/SVG11/feature#Extensibility"
//             overflow="visible"
//           >
//             <div
//               xmlns="http://www.w3.org/1999/xhtml"
//               style={{
//                 alignItems: "unsafe flex-start",
//                 justifyContent: "unsafe center",
//                 width: 115,
//                 height: 1,
//                 paddingTop: 130,
//                 marginLeft: 360
//               }}
//               display="flex"
//             >
//               <div
//                 style={{
//                   boxSizing: "border-box",
//                   textAlign: "center"
//                 }}
//                 fontSize={0}
//               >
//                 <div
//                   style={{
//                     lineHeight: 1.2,
//                     whiteSpace: "normal",
//                     wordWrap: "normal"
//                   }}
//                   display="inline-block"
//                   fontSize="12px"
//                   fontFamily="Helvetica"
//                   color="#000"
//                   pointerEvents="all"
//                 >
//                   （{props.kokudo_height_ratio}）
//                 </div>
//               </div>
//             </div>
//           </foreignObject>
//           <text
//             x={411}
//             y={125}
//             fontFamily="Helvetica"
//             fontSize="12px"
//             textAnchor="middle"
//           >
//             {props.kokudo_height_ratio}
//           </text>
//         </switch>
//       </g>
//     </svg>
//   )
// }

// export default SvgComponent

      //   <svg
      //   height={140}
      //   width={170}
      //   style={{
      //     flex: 1
      //   }}
      //   {...props}
      // >
      //   <path stroke="#fff" fill="red" d="M10 30H49V130H10z" />
      //   <path stroke="#fff" fill="orange" d="M50 60H89V130H50z" />
      //   <path stroke="#fff" fill="#ff0" d="M90 90H129V130H90z" />
      //   <text
      //     x={20}
      //     y={20}
      //     fontFamily="16px Arial"
      //     fontWeight="bold"
      //     fontSize={16}
      //     textDecoration="underline"
      //     fill="red"
      //   >
      //     <tspan fontSize={15} fill="#333">
      //       {" 40\u4EBA/km2\u4EE5\u4E0A "}
      //     </tspan>
      //   </text>
      //   <text
      //     x={60}
      //     y={50}
      //     fontFamily="16px Arial"
      //     fontWeight="bold"
      //     fontSize={16}
      //     textDecoration="underline"
      //     fill="orange"
      //   >
      //     <tspan fontSize={15} fill="#333">
      //       {" 20\u4EBA/km2\u4EE5\u4E0A "}
      //     </tspan>
      //   </text>
      // </svg>
    

{/*
  svg converter
  https://transform.tools/svg-to-jsx

  ===
<svg height="140" width="170" style="flex:1;" xmlns="http://www.w3.org/2000/svg"
  xmlns:xlink="http://www.w3.org/1999/xlink">
  
  <rect x="10" y="30" height="100" width="39"
    style="stroke:white; fill: red"/>
  <rect x="50" y="60" height="70" width="39"
    style="stroke:white; fill: orange"/>
  <rect x="90" y="90" height="40" width="39"
    style="stroke:white; fill: yellow"/>

  <text x="20" y="20" font-family="16px Arial" font-weight="bold" font-size="16" font-style="" text-decoration="underline" fill="red">
    <tspan font-size="15" fill="#333">
      40人/km2以上
    </tspan>
  </text>    

  <text x="60" y="50" font-family="16px Arial" font-weight="bold" font-size="16" font-style="" text-decoration="underline" fill="orange">
    <tspan font-size="15" fill="#333">
      20人/km2以上
    </tspan>
  </text>    

  </svg>

===


    return <div style={{background: "green", color: "white"}}>
            <div>



            <div>
<h2>凡例</h2>
<canvas id="kokudo_hanrei" styleName={Styles.canvas}>
図形を表示するには、canvasタグをサポートしたブラウザが必要です。
</canvas>
</div>



            <div>
              {data.site.siteMetadata.title}
              
            </div>
            <div>
              {props.data.markdownRemark.frontmatter.title}
            </div>
            </div>

          </div>
  }

*/}


  