import React, { useEffect, useState } from "react";
import { graphql, Link } from 'gatsby';
import _ from 'lodash';
import Styles from "./blog-post.module.css"
import Hanrei from "../containers/hanrei-kokudo"
import CesiumContainer from "../pages/cesiumContainer20"
import { UrlTemplateImageryProvider, Credit } from 'cesium'

const imageryProvider = new UrlTemplateImageryProvider({
  url: 'https://cyberjapandata.gsi.go.jp/xyz/pale/{z}/{x}/{y}.png',
  credit: new Credit('地理院タイル', '', 'https://maps.gsi.go.jp/development/ichiran.html')
})

const BlogPostTemplate = (props: any) => {
  const post = props.data.markdownRemark;
  const title = post.frontmatter.title;
  const slug = post.fields.slug;

  const kml_kmz='/kml/kokudo/'+post.frontmatter.no+'_2次メッシュ.kmz'  
  const kml_kml='/kml/kokudo/'+post.frontmatter.no+'_2次メッシュ.kml'

  const kmldown1='/kml/kokudo/'+post.frontmatter.no+'_2次メッシュ.kmz'
  const kmldown1a='/kml/kokudo/'+post.frontmatter.no+'_2次メッシュ.kml'
  // const kmldown1a='/kml/'+post.frontmatter.no+'/国土_' + post.frontmatter.kml_file.substring(0, post.frontmatter.kml_file.length-1) + 'l'

  const disqusConfig = {
    shortname: process.env.GATSBY_DISQUS_NAME,
    config: { identifier: slug, title },
  };



  const [showTotalCases, setShowTotalCases] = useState(true)
  const [showTotalDeaths, setShowTotalDeaths] = useState(false)
  const [showTotalCasesPM, setShowTotalCasesPM] = useState(false)
  const [showTotalDeathsPM, setShowTotalDeathsPM] = useState(false)

  const [customLatitude, setCustomLatitude] = useState(35)
  const [customLongitude, setCustomLongitude] = useState(135)
  const [showModal, setShowModal] = useState(false)

  const toolBoxStyle = {
    width: '20%',
    color: 'white',
    top: '0px',
    left: '75%',
    zIndex: 2,
    position: 'relative'
  }







  return (
    <div>
      

{/* <div>
  <Link to={"/"+post.frontmatter.no+"/0"} className={Styles.dis2}><h2>国土像を俯瞰する（全国の傾向を見る）</h2></Link>
</div> */}


  <div>

  <div style={{display:"flex",position:"relative"}}>
    <div style={{width:"30%",margin:"0% 0% 0% 0%",position:"absolute",zIndex:999}}>
      <div>
      <div >
        <Hanrei
            frontmatter={post.frontmatter}
            no={post.frontmatter.no}
            title={post.frontmatter.title}
            date={post.frontmatter.date}
            sdgs_no={post.frontmatter.sdgs_no}
            sdgs_main={post.frontmatter.sdgs_main}
            sdgs_subcat={post.frontmatter.sdgs_subcat}
            kou_tisou_pt={post.frontmatter.kou_tisou_pt}
            kou_tisou_chp={post.frontmatter.kou_tisou_chp}
            kou_tisou_sec={post.frontmatter.kou_tisou_sec}
            kou_tisou_sbsec={post.frontmatter.kou_tisou_sbsec}
            kou_tisou_title={post.frontmatter.kou_tisou_title}
            kou_titan_pt={post.frontmatter.kou_titan_pt}
            kou_titan_chp={post.frontmatter.kou_titan_chp}
            kou_titan_sec={post.frontmatter.kou_titan_sec}
            kou_titan_sbsec={post.frontmatter.kou_titan_sbsec}
            kou_titan_title={post.frontmatter.kou_titan_title}
            description={post.frontmatter.description}
            height_name={post.frontmatter.height_name}
            height_origin={post.frontmatter.height_origin}
            height_origin_note={post.frontmatter.height_origin_note}
            height_origin_url_name={post.frontmatter.height_origin_url_name}
            height_origin_url_name2={post.frontmatter.height_origin_url_name2}
            height_origin_url={post.frontmatter.height_origin_url}
            height_origin_url2={post.frontmatter.height_origin_url2}
            color_name={post.frontmatter.color_name}
            color_origin_note={post.frontmatter.color_origin_note}
            color_origin={post.frontmatter.color_origin}
            color_origin_url_name={post.frontmatter.color_origin_url_name}
            color_origin_url2_name={post.frontmatter.color_origin_url2_name}
            color_origin_url={post.frontmatter.color_origin_url}
            color_origin_url2={post.frontmatter.color_origin_url2}
            sokuchi={post.frontmatter.sokuchi}
            mesh_size={post.frontmatter.mesh_size}
            year_display={post.frontmatter.year_display}
            honbun={post.frontmatter.honbun}
            chyusyaku={post.frontmatter.chyusyaku}
            hosoku={post.frontmatter.hosoku}
            kml_file={post.frontmatter.kml_file}
            hanrei1_color={post.frontmatter.hanrei1_color}
            hanrei2_color={post.frontmatter.hanrei2_color}
            hanrei3_color={post.frontmatter.hanrei3_color}
            hanrei4_color={post.frontmatter.hanrei4_color}
            hanrei1_name={post.frontmatter.hanrei1_name}
            hanrei2_name={post.frontmatter.hanrei2_name}
            hanrei3_name={post.frontmatter.hanrei3_name}
            hanrei4_name={post.frontmatter.hanrei4_name}
            height_ratio={post.frontmatter.height_ratio}
            kokudo_hanrei1_name={post.frontmatter.kokudo_hanrei1_name}
            kokudo_hanrei2_name={post.frontmatter.kokudo_hanrei2_name}
            kokudo_hanrei3_name={post.frontmatter.kokudo_hanrei3_name}
            kokudo_hanrei4_name={post.frontmatter.kokudo_hanrei4_name}
            kokudo_height_ratio={post.frontmatter.kokudo_height_ratio}
          />
        </div>
      </div>
    </div>
    <div style={{width:"100vw", margin:"0 0 0 0"}}>
      <div style={{display: "flex"}}>
      <div className={Styles.panel}>
        <div className={Styles.cesiumcontainer}>
            <CesiumContainer
              kml_kml={kml_kml}
              kml_kmz={kml_kmz}
              // dir="/kml/sample.kml"
              width="100vw"
              height="90vh"
              full_name="Return"
              tofull_url={"/"+post.frontmatter.no+"/kokudof"}
              full_url={post.frontmatter.no+"/kokudo"}
              no={post.frontmatter.no}
              year1={post.frontmatter.year1}
              year2={post.frontmatter.year2}
              year3={post.frontmatter.year3}
              year4={post.frontmatter.year4}
              year5={post.frontmatter.year5}
              kokudo_min={post.frontmatter.kokudo_min}
              kokudo_ratio={post.frontmatter.kokudo_ratio}
              kokudo_shikii1={post.frontmatter.kokudo_shikii1}
              kokudo_shikii2={post.frontmatter.kokudo_shikii2}
              kokudo_shikii3={post.frontmatter.kokudo_shikii3}
              hanrei1_color={post.frontmatter.hanrei1_color}
              hanrei2_color={post.frontmatter.hanrei2_color}
              hanrei3_color={post.frontmatter.hanrei3_color}
              hanrei4_color={post.frontmatter.hanrei4_color}

            />
            </div>

      </div>
      </div>


      </div>
    </div>
    </div>





    </div>
  );
};

export default BlogPostTemplate;

export const pageQuery = graphql`
  query BlogPostBySlugkokudof($slug: String!, $tag: [String!]) {
    site {
      siteMetadata {
        siteUrl
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      excerpt(pruneLength: 160)
      html
      fileAbsolutePath
      fields {
        slug
      }
      frontmatter {
        no
        title
        date(formatString: "DD MMM, YYYY")
        description
        tags
        sdgs_no
        sdgs_main
        sdgs_subcat
        kou_tisou_pt
        kou_tisou_chp
        kou_tisou_sec
        kou_tisou_sbsec
        kou_tisou_title
        kou_titan_pt
        kou_titan_chp
        kou_titan_sec
        kou_titan_sbsec
        kou_titan_title
        height_name
        height_origin
        height_origin_note
        height_origin_url_name
        height_origin_url
        height_origin_url2_name
        height_origin_url2
        height_origin_url3_name
        height_origin_url3
        height_origin_url4_name
        height_origin_url4
        color_name
        color_origin
        color_origin_note
        color_origin_url_name
        color_origin_url
        color_origin_url2_name
        color_origin_url2
        color_origin_url3_name
        color_origin_url3
        color_origin_url4_name
        color_origin_url4
        sokuchi
        mesh_size
        year_display
        honbun
        chyusyaku
        hosoku
        tags
        kml_file
        y202012
        y202101
        y202102
        description
        hanrei1_color
        hanrei2_color
        hanrei3_color
        hanrei4_color
        hanrei1_name
        hanrei2_name
        hanrei3_name
        hanrei4_name
        height_ratio
        kokudo_hanrei1_name
        kokudo_hanrei2_name
        kokudo_hanrei3_name
        kokudo_hanrei4_name
        kokudo_height_ratio
        year1
        year2
        year3
        year4
        year5
        kokudo_min
        kokudo_ratio
        kokudo_shikii1
        kokudo_shikii2
        kokudo_shikii3
  }
    }
    allMarkdownRemark(
      limit: 3
      sort: { fields: [frontmatter___date], order: DESC }
      filter: {
        frontmatter: { tags: { in: $tag } }
        fields: { slug: { ne: $slug } }
      }
    ) {
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            no
            title
            tags

            sdgs_no
            sdgs_main
            sdgs_subcat
            kou_tisou_pt
            kou_tisou_chp
            kou_tisou_sec
            kou_tisou_sbsec
            kou_tisou_title
            kou_titan_pt
            kou_titan_chp
            kou_titan_sec
            kou_titan_sbsec
            kou_titan_title
            height_name
            height_origin
            height_origin_note
            height_origin_url_name
            height_origin_url
            height_origin_url2_name
            height_origin_url2
            height_origin_url3_name
            height_origin_url3
            height_origin_url4_name
            height_origin_url4
            color_name
            color_origin
            color_origin_note
            color_origin_url_name
            color_origin_url
            color_origin_url2_name
            color_origin_url2
            color_origin_url3_name
            color_origin_url3
            color_origin_url4_name
            color_origin_url4
            sokuchi
            mesh_size
            year_display
            honbun
            chyusyaku
            hosoku
            tags
            kml_file
            y202012
            y202101
            y202102
            description
            hanrei1_name
            hanrei1_color
            hanrei2_name
            hanrei2_color
            hanrei3_name
            hanrei3_color
            hanrei4_name
            hanrei4_color
            height_ratio
            year1
            year2
            year3
            year4
            year5
            kokudo_min
            kokudo_ratio
            kokudo_shikii1
            kokudo_shikii2
            kokudo_shikii3
          }
        }
      }
    }
  }
`;

